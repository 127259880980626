/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./styles/app.sass";
import "../node_modules/animate.css/animate.min.css";
import Page from "./components/Page";
import Loadable from "./components/Loadable";
import ReactGA from "react-ga";
import clarity from '@microsoft/clarity';


// import Home from "./screens/Home";
// import Features from "./screens/Features";
// import Pricing from "./screens/Pricing";
// import Download from "./screens/Download";
// import Class01 from "./screens/Class01";
// import Class01Details from "./screens/Class01Details";
// import Class02 from "./screens/Class02";
// import Class02Details from "./screens/Class02Details";
// import Lifestyle from "./screens/Lifestyle";
// import Article from "./screens/Article";
// import Careers from "./screens/Careers";
// import Blogs from "./screens/Blogs";
// import CaseStudies from "./screens/CaseStudies";
// import Services from "./screens/Services";
// import Support from './screens/Support';
// import BookAppointment from "./screens/BookAppointment";
// import ApplyForm from './screens/ApplyForm';
// import BlogsDetails from "./screens/BlogsDetails";
// import AboutUs from "./screens/AboutUs";

//import UberEatsDetails from "./screens/UberEatsBlogDetails";
//import UXDesignDetails from "./screens/UXDesignBlogDetails";
// import ServiceDeskDetails from "./screens/ServiceDeskBlogDetails"

const Home = Loadable(lazy(() => import("./screens/Home")));
const Careers = Loadable(lazy(() => import("./screens/Careers")));
const Blogs = Loadable(lazy(() => import("./screens/Blogs")));
const CaseStudies = Loadable(lazy(() => import("./screens/CaseStudies")));
const Services = Loadable(lazy(() => import("./screens/Services")));
const Support = Loadable(lazy(() => import("./screens/Support")));
const BookAppointment = Loadable(
  lazy(() => import("./screens/BookAppointment"))
);
const ApplyForm = Loadable(lazy(() => import("./screens/ApplyForm")));
const AboutUs = Loadable(lazy(() => import("./screens/AboutUs")));
const GoogleMapDetails = Loadable(
  lazy(() => import("./screens/GoogleMapBlogsDetails"))
);
const UberEatsDetails = Loadable(
  lazy(() => import("./screens/UberEatsBlogDetails"))
);
const UXDesignDetails = Loadable(
  lazy(() => import("./screens/UXDesignBlogDetails"))
);
const ServiceDeskDetails = Loadable(
  lazy(() => import("./screens/ServiceDeskBlogDetails"))
);
const CareerDetails = Loadable(lazy(() => import("./screens/CareerDetails")));
const ServiceDesign = Loadable(lazy(() => import("./screens/ServiceDesign")));
const ServiceDevelopment = Loadable(
  lazy(() => import("./screens/ServiceDevelopment"))
);
function App() {
  const location = useLocation(); // Use useLocation hook frontend-developer@uitoux-website.iam.gserviceaccount.com
  // const [seoData, setSeoData] = useState([]);
  const [currentPageSeo, setCurrentPageSeo] = useState({});
  const [currentPageSchema, setCurrentPageSchema] = useState({});

  // Helper function to parse CSV data
  function parseCSV(csvData) {
    const lines = csvData.split("\n");
    const arrayResult = [];

    lines.forEach((line) => {
      let insideQuotes = false;
      let currentField = "";
      const fields = [];

      for (let i = 0; i < line.length; i++) {
        const char = line[i];

        if (char === '"') {
          insideQuotes = !insideQuotes;
        } else if (char === "," && !insideQuotes) {
          fields.push(currentField.trim()); // Trim whitespace
          currentField = "";
        } else {
          currentField += char;
        }
      }

      fields.push(currentField.trim()); // Trim whitespace
      arrayResult.push(fields);
    });

    const keys = arrayResult[0]; // Extract the key from the first array value
    //console.log('keys => ', keys)
    const values = arrayResult.slice(1); // Extract the remaining values
    //console.log('values => ', values);

    const result = values.map((valueArray) => {
      const obj = {};
      keys.forEach((key, index) => {
        obj[key] = valueArray[index];
      });
      return obj;
    });

    return result;
  }

  // Function to get SEO data for the current page
  const getCurrentPageSeo = (csvData) => {
    const currentPageUrl = window.location.href;
    return csvData?.find(
      (data) =>
        data.prodURL === currentPageUrl ||
        data.uatURL === currentPageUrl ||
        data.devURL === currentPageUrl
    );
  };

  // Dynamically generate Schema Markup based on the current page
  const generateSchemaMarkup = (csvData) => {
    const currentPageSeo = getCurrentPageSeo(csvData);
    if (!currentPageSeo) return null;

    const currentPageUrl = window.location.href;
    const todayDate = new Date();
    let schemaMarkup = null;

    // Determine the Schema type based on the current page
    switch (currentPageSeo.pageType) {
      case "organization":
        schemaMarkup = {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: currentPageSeo.schemaName || "UITOUX Solutions",
          url: currentPageUrl || currentPageSeo?.prodURL,
          logo: "https://www.uitouxsolutions.com/images/logo-dark.svg",
          address:
            "1st Floor, No. 20, 147-A, E Ponnurangam Rd, R.S. Puram, Coimbatore, Tamil Nadu 641002",
          telephone: "+91 89252 97807",
          email: "hello@uitoux.in",
          sameAs: ["", ""],
          // Add other relevant properties for the Organization schema
        };
        break;
      case "articles":
        schemaMarkup = {
          "@context": "https://schema.org",
          "@type": "Article",
          headline: currentPageSeo.schemaName || "Default Article Title",
          description:
            currentPageSeo.schemaDescription || "Default Article Description",
          url: currentPageUrl || currentPageSeo?.prodURL,
          author: {
            "@type": currentPageSeo?.authorType || "Organization",
            name: currentPageSeo?.authorName || "UITOUX",
          },
          publisher: {
            "@type": currentPageSeo?.publisherType || "Organization",
            name: currentPageSeo?.publisherName || "UITOUX",
            logo: {
              "@type": "ImageObject",
              url: "https://www.uitouxsolutions.com/images/logo-icon.svg",
            },
          },
          datePublished: currentPageSeo?.articlePublishedDate || todayDate,
          dateModified: currentPageSeo?.articlePublishedDate || todayDate,
          // Add other relevant properties for the Article schema
        };
        break;
      case "services":
        const serviceType = currentPageSeo?.serviceType?.split(",");
        schemaMarkup = {
          "@context": "https://schema.org",
          "@type": "Service",
          name: currentPageSeo.schemaName || "Default Service Name",
          description:
            currentPageSeo.schemaDescription || "Default Service Description",
          provider: {
            "@type": "Organization",
            name: currentPageSeo?.companyName || "UITOUX Solutions",
            description:
              currentPageSeo?.companyDescription ||
              "Description of your company.",
            // Add additional organization properties if needed
          },
          serviceType: serviceType || "Default Service Type",
          // Add other relevant properties for the Service schema
        };
        break;
      // Add more cases for other page types as needed
      default:
        schemaMarkup = {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          name: currentPageSeo?.companyName || "UITOUX Solutions",
          url: currentPageUrl,
          potentialAction: {
            "@type": "SearchAction",
            target: "{search_term_string}",
            "query-input": "required name=search_term_string",
          },
        };
        break;
    }

    return schemaMarkup;
  };

  // Fetch SEO data from the server
  const fetchSeoData = () => {
    const sheetURL =
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vRb7Ox3lESCVoeQWWLyMhqTRQZO3xiNO6YpUJPsxF0R3jM3I2wgdIPRAUEHNOi-vl-pDjbLXK5NwDwA/pub?output=csv";
    axios
      .get(sheetURL)
      .then((response) => {
        //console.log("Sheets Resp => ", response);
        const parsedCsvData = parseCSV(response.data);
        //setSeoData(parsedCsvData);
        setCurrentPageSeo(getCurrentPageSeo(parsedCsvData));
        setCurrentPageSchema(generateSchemaMarkup(parsedCsvData));
        // console.log("parseCSV data => ", parsedCsvData);
      })
      .catch((error) => {
        console.error("Error fetching SEO data:", error); // Handle errors
      });
  };

  // Dynamically generate Schema Markup for the current page
  // const currentPageSeo = getCurrentPageSeo();

  // Generate Schema Markup for the current page
  // const currentPageSchema = generateSchemaMarkup();

  // console.log("currentPageSeo => ", currentPageSeo, window.location.href);
  // console.log("Curret Page Schema => ", currentPageSchema);
  // console.log("Use Location => ", location);

  useEffect(() => {
    /* const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=UA-77206360-1`;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-77206360-1', { page_path: location.pathname });
      }
    };

    return () => {
      document.head.removeChild(script);
    }; */

    // Fetch SEO Data

    clarity.init("pie8digphg");

    fetchSeoData();

    // Add the Google Tag Manager scripts directly
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="app">
      <Helmet>
        <title>
          {currentPageSeo?.metaTitle
            ? currentPageSeo?.metaTitle
            : "Global Software Solutions for Enterprise | UITOUX Solutions"}
        </title>
        <meta
          name="description"
          content={
            currentPageSeo?.metaDescription
              ? currentPageSeo?.metaDescription
              : "IT enterprise solutions! Building and enhancing products. Supercharge your business with top-notch software. Let's team up for success! #worldclassproducts"
          }
        />
        <meta
          name="keywords"
          content={
            currentPageSeo?.targetKeyword
              ? currentPageSeo?.targetKeyword
              : "Enterprise Software Solutions"
          }
        />
        <meta
          property="og:title"
          content={
            currentPageSeo?.metaTitle
              ? currentPageSeo?.metaTitle
              : "Global Software Solutions for Enterprise | UITOUX Solutions"
          }
        />
        <meta
          property="og:description"
          content={
            currentPageSeo?.metaDescription
              ? currentPageSeo?.metaDescription
              : "IT enterprise solutions! Building and enhancing products. Supercharge your business with top-notch software. Let's team up for success! #worldclassproducts"
          }
        />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify(currentPageSchema)}
        </script>
      </Helmet>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <Page>
                <Home />
              </Page>
            }
          />
          <Route
            path="about-us"
            element={
              <Page>
                <AboutUs />
              </Page>
            }
          />
          <Route
            path="case-studies"
            element={
              <Page>
                <CaseStudies />
              </Page>
            }
          />
          <Route path="book-appointment" element={<BookAppointment />} />
          <Route path="apply-form" element={<ApplyForm />} />
        </Route>
        <Route path="careers">
          <Route
            index
            element={
              <Page>
                <Careers />
              </Page>
            }
          />
          <Route
            path="career-details/:id"
            element={
              <Page>
                <CareerDetails />
              </Page>
            }
          />
        </Route>
        <Route path="blogs">
          <Route
            index
            element={
              <Page>
                <Blogs />
              </Page>
            }
          />
          <Route
            path="google-map-details"
            element={
              <Page>
                <GoogleMapDetails />
              </Page>
            }
          />
          <Route
            path="uber-eats-details"
            element={
              <Page>
                <UberEatsDetails />
              </Page>
            }
          />
          <Route
            path="ux-design-details"
            element={
              <Page>
                <UXDesignDetails />
              </Page>
            }
          />
          <Route
            path="service-desk-details"
            element={
              <Page>
                <ServiceDeskDetails />
              </Page>
            }
          />
        </Route>

        <Route path="services">
          <Route
            index
            element={
              <Page>
                <Services />
              </Page>
            }
          />
          <Route
            path="uitoux-support-desk"
            element={
              <Page overflowHidden={false}>
                <Support />
              </Page>
            }
          />
          <Route
            path="development"
            element={
              <Page overflowHidden={false}>
                <ServiceDevelopment />
              </Page>
            }
          />
          <Route
            path="design"
            element={
              <Page overflowHidden={false}>
                <ServiceDesign />
              </Page>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
